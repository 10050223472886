import axios from "axios";

export default {
  async getDispatchHistory(fromDate, toDate) {
    return await axios
      .get(`dispatch/dispatch-history?fromDate=${fromDate}&toDate=${toDate}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async viewDetails(id) {
    return await axios
      .get(`dispatch/child-by-master/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async downloadBarCode(id) {
    return await axios
      .get(`dispatch/bar-code/${id}`, {
        responseType: "blob",
      })
      .then((res) => {
        const url = URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.ms-excel",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Bar-code.txt`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
        throw new Error(err.response.data.message);
      });
  },
  async singleExcelImport(id, dispatch_code) {
    return await axios
      .get(`dispatch/excel-by-master-id/${id}`, {
        responseType: "blob",
      })
      .then((res) => {
        const url = URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.ms-excel",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `RM-Arrival-${dispatch_code}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
        throw new Error(err.response.data.message);
      });
  },
  async dispatchDownload(id, dispatch_code) {
    return await axios
      .get(`dispatch/dispatch-download/${id}`, {
        responseType: "blob",
      })
      .then((res) => {
        const url = URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.ms-excel",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `RM-Arrival-${dispatch_code}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
        throw new Error(err.response.data.message);
      });
  },

  async downloadExcel(id, po_number) {
    return await axios
      .post(
        `dispatch/delivery-notes`,
        { dispatchMasterID: id, po_number: po_number },
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        const url = URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.ms-excel",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${po_number}.xls`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async downloadPaymentDetails(code) {
    return await axios
      .post(
        `report/download-payment-details/`,
        { dispatch_code: code },
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        const url = URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.ms-excel",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${code}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async downloadPODetails(code) {
    return await axios
      .post(
        `report/download-PO-details/`,
        { dispatch_code: code },
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        const url = URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.ms-excel",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `PO-details-${code}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  },
  // async downloadExcelDeliveryNotesWithPO(po) {
  //   return await axios
  //     .post(
  //       ``,
  //       { dispatchMasterID: po },
  //       {
  //         responseType: "blob",
  //       }
  //     )
  //     .then((res) => {
  //       const url = URL.createObjectURL(
  //         new Blob([res.data], {
  //           type: "application/vnd.ms-excel",
  //         })
  //       );
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", "received-items.xlsx");
  //       document.body.appendChild(link);
  //       link.click();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // },
};
