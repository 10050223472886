<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Delivery History
                    </h5>
                  </div>
                </div>
              </div>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                    <label class="text-md text-typo font-weight-bolder ms-1"
                      ><span class="red--text"><strong>* </strong></span>From
                      (Start date)</label
                    >

                    <v-menu
                      ref="start_date_menu"
                      v-model="start_date_menu"
                      :close-on-content-click="false"
                      :return-value.sync="start_date_menu"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="start_date"
                          v-bind="attrs"
                          v-on="on"
                          hide-details="auto"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                            mt-5
                          "
                          dense
                          flat
                          filled
                          solo
                          height="46"
                          placeholder="Start Date"
                          persistent-hint
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="green lighten-1"
                        header-color="primary"
                        v-model="start_date"
                        :max="end_date"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="start_date_menu = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.start_date_menu.save(start_date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                    <label class="text-md text-typo font-weight-bolder ms-1"
                      ><span class="red--text"><strong>* </strong></span>To (End
                      date)</label
                    >

                    <v-menu
                      ref="end_date_menu"
                      v-model="end_date_menu"
                      :close-on-content-click="false"
                      :return-value.sync="end_date_menu"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="end_date"
                          v-bind="attrs"
                          v-on="on"
                          hide-details="auto"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                            mt-5
                          "
                          dense
                          flat
                          filled
                          solo
                          height="46"
                          placeholder="To Date"
                          persistent-hint
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="green lighten-1"
                        header-color="primary"
                        v-model="end_date"
                        :min="start_date"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="end_date_menu = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.end_date_menu.save(end_date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row> </v-card-text
              ><v-card-actions dense class="card-padding d-flex justify-end">
                <v-btn
                  @click="clear"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="
                    font-weight-bold
                    text-capitalize
                    btn-ls btn-secondary
                    bg-light
                    px-6
                  "
                  >Clear</v-btn
                >

                <v-btn
                  @click="search()"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  class="
                    font-weight-bold
                    text-capitalize
                    btn-ls btn-primary
                    bg-success
                    px-6
                  "
                  >Search</v-btn
                >
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-card class="card-shadow border-radius-xl mt-5" v-show="showTable">
        <v-card-text class="card-padding">
          <!-- Data Table Master -->

          <v-data-table
            v-model="selectedData"
            :headers="masterHeader"
            :items="dispatchMasterFiltered"
            item-key="id"
            mobile-breakpoint="0"
            fixed-header
            class="table"
            :footer-props="{
              'items-per-page-options': [100, 200, 500, -1],
            }"
            :search="filter.search"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <!-- <v-form ref="frmFilter" width="100%"> -->
                <v-row>
                  <v-col cols="12" md="3" lg="3">
                    <v-text-field
                      hide-details
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        input-icon
                        mr-5
                        mt-4
                      "
                      dense
                      flat
                      filled
                      solo
                      height="46"
                      v-model="filter.search"
                      placeholder="Search with : Dispatch Code"
                    >
                      <template slot="prepend-inner">
                        <v-icon color="#adb5bd" size=".875rem"
                          >fas fa-search</v-icon
                        >
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" lg="3">
                    <v-autocomplete
                      v-model="filter.type"
                      @change="filterResult()"
                      :items="type"
                      item-text="name"
                      item-value="name"
                      color="rgba(0,0,0,.6)"
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        border-radius-md
                        select-style
                        mb-0
                        mt-4
                      "
                      placeholder="Filter by type"
                      outlined
                      single-line
                      height="46"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3" lg="3">
                    <v-autocomplete
                      v-model="filter.status"
                      @change="filterResult()"
                      :items="status"
                      item-text="name"
                      item-value="name"
                      color="rgba(0,0,0,.6)"
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        border-radius-md
                        select-style
                        mb-0
                        mt-4
                      "
                      placeholder="Filter by status"
                      outlined
                      single-line
                      height="46"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3" lg="3">
                    <v-btn
                      @click="clearFilter"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-secondary
                        bg-light
                        px-6
                        mt-4
                      "
                      >Clear</v-btn
                    >
                  </v-col>
                </v-row>
                <!-- </v-form> -->
              </v-toolbar>
            </template>

            <template v-slot:[`item.purchase_type`]="{ item }">
              <span>
                <v-chip
                  label
                  small
                  :ripple="false"
                  :color="typeColor(item).color"
                >
                  {{ item.purchase_type }}
                </v-chip>
              </span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <span>
                <v-chip
                  label
                  small
                  :ripple="false"
                  :color="syncStatus(item).color"
                >
                  {{ syncStatus(item).label }}
                </v-chip>
              </span>
            </template>
            <template v-slot:[`item.date`]="{ item }">
              {{ item.date.split("T")[0] }}
            </template>
            <template v-slot:[`item.recieved_date`]="{ item }">
              {{ dateFormater(item.recieved_date) }}
            </template>
            <template v-slot:[`item.dispatch_date`]="{ item }">
              {{ dateFormater(item.dispatch_date) }}
            </template>
            <template v-slot:[`item.action1`]="{ item }">
              <v-menu>
                <!-- <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="
                      font-weight-bold
                      btn-ls btn-primary
                      bg-success
                      py-2
                      px-6
                      me-2
                    "
                    small
                    dark
                  >
                    Actions
                    <v-icon>ni ni-bold-down text-xs ms-1</v-icon>
                  </v-btn>
                </template> -->

                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    :ripple="false"
                    class="text-secondary"
                    v-bind="attrs"
                    v-on="on"
                    small
                  >
                    <v-icon size="16">fas fa-ellipsis-v</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item-group>
                    <v-list class="py-0">
                      <v-list-item @click="viewDetails(item)">
                        <v-list-item-content class="pa-0">
                          <v-list-item-title
                            class="
                              text-body-2
                              ls-0
                              text-typo
                              font-weight-600
                              mb-0
                            "
                            >View
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <!-- BAR CODE  -->
                    <v-list class="py-0">
                      <v-list-item @click="barCode(item)">
                        <v-list-item-content class="pa-0">
                          <v-list-item-title
                            class="
                              text-body-2
                              ls-0
                              text-typo
                              font-weight-600
                              mb-0
                            "
                            >Download Bar-Code
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <!--  -->
                    <v-list
                      class="py-0"
                      v-if="item.dispatch_status == 'CONFIRM'"
                    >
                      <v-list-item @click="excelImport(item)">
                        <v-list-item-content class="pa-0">
                          <v-list-item-title
                            class="
                              text-body-2
                              ls-0
                              text-typo
                              font-weight-600
                              mb-0
                            "
                            >Export as excel
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <!--  -->
                    <v-list class="py-0">
                      <v-list-item
                        @click="DownloadDeliveryNotes(item)"
                        v-if="item.dispatch_status == 'RECIEVED'"
                      >
                        <v-list-item-content class="pa-0">
                          <v-list-item-title
                            class="
                              text-body-2
                              ls-0
                              text-typo
                              font-weight-600
                              mb-0
                            "
                            >Download Delivery Note
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <!--  -->
                    <v-list class="py-0">
                      <v-list-item @click="downloadPoDetails(item)">
                        <v-list-item-content class="pa-0">
                          <v-list-item-title
                            class="
                              text-body-2
                              ls-0
                              text-typo
                              font-weight-600
                              mb-0
                            "
                            >PO-Details
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <!-- <v-list class="py-0">
                      <v-list-item
                        @click="enterDetails(item)"
                        v-if="item.status == 'CONFIRMED'"
                      >
                        <v-list-item-content class="pa-0">
                          <v-list-item-title
                            class="
                              text-body-2
                              ls-0
                              text-typo
                              font-weight-600
                              mb-0
                            "
                            >Enter Details
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list> -->

                    <!--  -->
                    <v-list class="py-0">
                      <v-list-item
                        @click="downloadPaymentDetails(item)"
                        v-if="item.purchase_type == 'AUCTION'"
                      >
                        <v-list-item-content class="pa-0">
                          <v-list-item-title
                            class="
                              text-body-2
                              ls-0
                              text-typo
                              font-weight-600
                              mb-0
                            "
                            >Download Payment Details
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>

    <v-dialog v-model="dialogAuctionDeliveryNote" persistent max-width="800">
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding">
          <div class="d-flex align-center">
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Download Delivery notes
              </h5>
            </div>
          </div>
        </div>
        <v-card-text class="card-padding">
          <v-row v-for="item in auctionDeliveryNote.po" :key="item.po_number">
            <v-col cols="4">
              <!-- <v-row class="tittle__row">
                <label class="text-md text-typo ms-1">PO Number</label>
              </v-row> -->
              <v-row>
                <label class="text-lg text-typo ms-1 label__value">{{
                  item.po_number
                }}</label>
              </v-row>
            </v-col>
            <v-col cols="4">
              <v-btn
                @click="downloadDeliveryNotesWithPO(item)"
                dark
                class="
                  font-weight-bold
                  text-capitalize
                  btn-primary
                  bg-success
                  py-5
                  px-5
                  shadow
                "
              >
                <v-icon size="20" class="text-white mr-1">
                  fas fa-cloud-download-alt
                </v-icon>
                Download
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="dialogAuctionDeliveryNote = false"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-secondary
              bg-light
              py-3
              px-6
            "
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "../api";
import formatDate from "../../../../global-data/dateFormatter";
export default {
  created() {
    this.setDates();
  },
  data() {
    return {
      dialogAuctionDeliveryNote: false,
      filter: {
        search: "",
        type: [],
        status: [],
      },
      type: [
        { name: "AUCTION" },
        { name: "PRIVATE" },
        { name: "INTERNAL TRANSFER" },
      ],
      status: [{ name: "PENDING" }, { name: "CONFIRM" }, { name: "RECIEVED" }],
      overlay: false,
      start_date_menu: false,
      end_date_menu: false,
      start_date: null,
      end_date: null,
      // Master Table
      showTable: false,
      selectedData: [],
      dispatchMaster: [],
      dispatchMasterFiltered: [],
      masterHeader: [
        {
          text: "Date",
          value: "dispatch_date",
          class: "text-secondary font-weight-bolder opacity-7",
          align: "left",
          sortable: false,
          filterable: true,
        },

        {
          text: "Type",
          value: "purchase_type",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
          filterable: false,
        },
        {
          text: "Status",
          value: "status",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
          filterable: false,
        },
        {
          text: "Received date",
          value: "recieved_date",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
          filterable: false,
        },
        {
          text: "Order(Bags)",
          value: "ordered_no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
          filterable: false,
        },
        {
          text: "Order(Qty)",
          value: "ordered_quantity",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
          filterable: false,
        },
        {
          text: "Received(Bags)",
          value: "received_no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
          filterable: false,
        },
        {
          text: "Received(Qty)",
          value: "received_quantity",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
          filterable: false,
        },

        {
          value: "action1",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
          filterable: false,
        },
        {
          text: "Dispatch Code",
          value: "dispatch_code",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
          filterable: true,
        },
      ],
      // Child dataTable
      auctionDeliveryNote: { po: [] },
      dispatchChild: [],
      dialogChild: false,
      header: [],
      dispatchChildHeader: [
        {
          text: "Batch No",
          value: "batch_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "Item Name",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "Ordered(Packages)",
          value: "ordered_no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "Ordered(Kgs)",
          value: "ordered_quantity",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
      ],
      recivedHeader: [
        {
          text: "Received(Packages)",
          value: "received_no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "Received(Kgs)",
          value: "received_quantity",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
      ],
      // Excel Gen

      masterID: 0,
    };
  },
  methods: {
    syncStatus(item) {
      if (item.dispatch_status === "PENDING") {
        return { label: "PENDING", color: "rgba(198, 128, 77, 0.12)" };
      } else if (item.dispatch_status === "RECIEVED") {
        return { label: "RECEIVED", color: "rgba(105, 240, 174, 0.5)" };
      } else if (item.dispatch_status === "CONFIRM") {
        return { label: "CONFIRMED", color: "rgb(0, 204, 255, 0.5)" };
      } else {
        return { label: item.dispatch_status, color: "rgba(237, 63, 59, 0.5)" };
      }
    },

    typeColor(item) {
      if (item.purchase_type === "INTERNAL TRANSFER") {
        return {
          color: "rgba(255, 191, 0, 0.5)",
        };
      } else if (item.purchase_type === "AUCTION") {
        return { color: "rgba(68, 255, 0, 0.5)" };
      } else if (item.purchase_type === "PRIVATE") {
        return { color: "rgba(224, 143, 219, 0.5)" };
      }
    },
    clearFilter() {
      this.filter.search = "";
      this.filter.type = [];
      this.filter.status = [];
      this.filterResult();
    },
    async filterResult() {
      this.dispatchMasterFiltered = this.dispatchMaster;
      if (this.filter.type.length > 0) {
        this.dispatchMasterFiltered = this.dispatchMasterFiltered.filter(
          (item) => {
            return this.filter.type.includes(item.purchase_type);
          }
        );
      }
      console.log(
        "this.filter.status.length",
        this.filter.status,
        this.filter.type
      );
      if (this.filter.status.length > 0) {
        this.dispatchMasterFiltered = this.dispatchMasterFiltered.filter(
          (item) => {
            return this.filter.status.includes(item.dispatch_status);
          }
        );
      }
    },

    async setDates() {
      let date = new Date();
      let d = new Date(date.getFullYear(), date.getMonth(), 1);
      let d1 = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.start_date = this.formatDate(d);
      this.end_date = this.formatDate(d1);

      await this.search();
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
    // Searching Master Data
    async getDispatchMaster() {
      this.dispatchMaster = await api.getDispatchHistory(
        this.start_date,
        this.end_date
      );
    },
    dateFormater(date) {
      if (date) return formatDate.formatDate(date);
    },
    async search() {
      try {
        this.dispatchMaster = [];
        this.overlay = true;
        await this.getDispatchMaster();
        this.dispatchMasterFiltered = this.dispatchMaster;

        if (this.dispatchMaster.length > 0) {
          this.showTable = true;
        } else {
          this.noDataAlert("No Data found");
        }
        this.overlay = false;
      } catch (error) {}
    },
    // Searching Child Data

    async viewDetails(item) {
      this.$router.push({
        name: "History-Details",
        params: { item: item },
      });
      //
    },

    async barCode(item) {
      await api.downloadBarCode(item.id);
      //
    },
    closeDialogue() {
      this.dialogChild = false;
    },

    //Excel Import Single Master
    async excelImport(item) {
      this.overlay = true;
      // console.log(item);
      await api.singleExcelImport(item.id, item.dispatch_code);
      this.overlay = false;
    },

    // Enter RM arrival Details

    async enterDetails(item) {
      this.$router.push({
        name: "RM Update",
        params: { id: item.id, purchase_type: item.purchase_type },
        query: {
          currentPage: this.currentPage,
        },
      });
    },

    //Download Delivery Note

    async DownloadDeliveryNotes(item) {
      this.overlay = true;
      // console.log(item);

      if (item.purchase_type === "AUCTION") {
        this.dialogAuctionDeliveryNote = true;
        // ! TODO: Add in backend
        this.auctionDeliveryNote = item;
        // await api.downloadExcel(item.id);
      } else {
        await api.downloadExcel(item.id, item.po[0].po_number);
      }
      this.overlay = false;
    },
    async downloadDeliveryNotesWithPO(po) {
      this.overlay = true;

      await api.downloadExcel(this.auctionDeliveryNote.id, po.po_number);
      this.overlay = false;
    },
    //downloadPaymentDetails

    async downloadPaymentDetails(item) {
      this.overlay = true;

      await api.downloadPaymentDetails(item.dispatch_code);
      this.overlay = false;
    },
    //  Download PO-detials
    async downloadPoDetails(item) {
      this.overlay = true;

      await api.downloadPODetails(item.dispatch_code);
      this.overlay = false;
    },

    // Excel import Multiple Master
    excelImportBulk() {
      console.log(this.selectedData);
      if (this.selectedData.length > 0) {
        let masterIDS = [];
        this.selectedData.forEach((el) => {
          masterIDS.push(el.id);
        });
      } else {
        this.showWarningAlert("Choose Atleast One Row...");
      }
    },

    clear() {},
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",

        icon: "error",
        showConfirmButton: true,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
    noDataAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,
        timer: 3000,
      });
    },
  },
};
</script>

<style></style>
